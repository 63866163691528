import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { useLocation, useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import { COUNTRIES } from "data/countries";
import { connect } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Grid } from "@mui/material";
import api from '../../api';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert } from "@mui/material";

const PageCompanyRegister = ({ user }) => {
  const location = useLocation();
  const {state} = useLocation();
  const navigate = useNavigate();
  const companyType = location.pathname.indexOf('brand')>-1 ? 'brand' : 'agency';
  const nextHref = `/register/${companyType}/2`;

  const initialValues = {
    user_id: state?.user?.id,
    company_type: companyType,
    name: '',
    description: '',
    business_type: "GmbH",
    street_address: "",
    post: "",
    city: "",
    country: "DE",
    email: "",
    website: "",
    phone: "",
    lat: 0,
    lng: 0,
  }
  const [inputValues, setInputValues] = useState(initialValues);

  const [sbState, setSBState] = useState({
    open: false,
  });
  const { open } = sbState;

  useEffect(() => {
    let company = {
      user_id: user?.id,
      ...user?.company,
    }
    
    setInputValues(company);

    console.log(user);
  }, [user]);

  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateCompany = () => {
    api.updateCompanyDetails(inputValues, (res) => {
      openSuccessSnackbar(true);
    });
  }

  const openSuccessSnackbar = (position) => {
    setSBState({ ...position, open: true });
  }

  const handleCloseSB = () => {
    setSBState({ ...sbState, open: false });
  }

  return (
      <>
        <div style={{ marginTop: 26 }} className="w-14"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem
          >
            <Input 
              placeholder="Company name"
              name='name'
              value={inputValues?.name}
              onChange={handleInputChange}
               />
          </FormItem>
          {/* ITEM */}
          <FormItem
            label="Business Type"
          >
            <Select 
              name='business_type'
              value={inputValues?.business_type}
              onChange={handleInputChange}
              >
              <option value="GmbH">GmbH</option>
              <option value="KG">KG</option>
              <option value="OHG">OHG</option>
              <option value="GBR">GBR</option>
              <option value="UG ">UG</option>
              <option value="AG">AG</option>
            </Select>
          </FormItem>
          <FormItem
            label="Email"
          >
            <Input 
              placeholder="Email"
              name='email'
              value={inputValues?.email}
              onChange={handleInputChange}
             />
          </FormItem>
          <FormItem
            label="Website"
          >
            <Input 
              placeholder="Website"
              name='website'
              value={inputValues?.website}
              onChange={handleInputChange} />
          </FormItem>
          <FormItem
            label="Phone"
          >
            <Input 
              placeholder="Telefon"
              name='phone'
              value={inputValues?.phone}
              onChange={handleInputChange} />
          </FormItem>
          <>
            <div>
              <h2 className="text-2xl font-semibold">
                About the Company
              </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Mention the best features of your product or service or the history of your company.
              </span>
            </div>

            <Textarea 
              placeholder="..."  
              rows={14}
              name='description'
              value={inputValues?.description}
              onChange={handleInputChange}> </Textarea>
          </>

          <FormItem label="Country/Region">
            <Select
              name='country'
              value={inputValues?.country}
              onChange={handleInputChange}
              >
              <option value="DE">Germany</option>
              {
                COUNTRIES.map((c) => (
                  <option value={c.code}>{c.name}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input 
              placeholder="..."
              name='street_address'
              value={inputValues?.street_address}
              onChange={handleInputChange}
               />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="City">
              <Input
                name='city'
              value={inputValues?.city}
              onChange={handleInputChange}
              />
            </FormItem>
            <FormItem label="Post">
              <Input 
                name='post'
              value={inputValues?.post}
              onChange={handleInputChange}
              />
            </FormItem>
          </div>
          <Grid container direction={'row'} className={'fullWidth'}>
            <div className={'flex-1'}></div>
            <ButtonPrimary onClick={handleUpdateCompany}>Save Changes</ButtonPrimary>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleCloseSB}
          message="Contact Requested"
        >
          <Alert
            onClose={handleCloseSB}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Details Updated!
          </Alert>
        </Snackbar>
      </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, {  })(
  PageCompanyRegister
);
