import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { user: {
    id: 0,
    name: '',
    email: '',
    company: null,
    company_type: null,
  } },
  reducers: {
    updateUser: (state, action) => {
        const updates = action.payload; // Destructure updates from the action payload
        state.user = { ...state.user, ...updates }; // Merge the updates into the state
    },
    logout: (state) => {
        localStorage.removeItem('token');
        window.location.href = window.location.origin;
    }
  },
});

export const { updateUser, logout } = userSlice.actions;

export default userSlice.reducer;