import instance from './interceptor';
import {getEnv} from './environments';

const Endpoints = {
    // auth
    auth: (payload, callback) => pushAuth('post', '/token', payload, callback), 
    refreshToken: (payload, callback) => pushAuth('post', '/token/refresh', payload, callback), 
    getUser: (callback) => pushApi('get', '/user', {}, callback), 

    // companies
    getUserCompanies: (payload, callback) => pushApi('get', `/user-affiliations/${payload.id}`, {}, callback),
    updateCompanyDetails: (payload, callback) => pushApi('put', `/company`, payload, callback),

    // Registrations
    registerUser: (payload, callback) => pushApi('post', '/users', payload, callback),
    registerCompany: (payload, callback) => pushApi('post', '/companies', payload, callback),

    // Pending Applications
    getPendingApplications: (callback) => pushApi('get', '/companies?is_confirmed=0', {}, callback),
    updateCompany: (payload, callback) => pushApi('put', `/company/${payload.id}`, payload, callback),
    deleteCompany: (payload, callback) => pushApi('delete', `/company/${payload.type}/${payload.id}`, {}, callback),

    // product group
    getProductGroups: (callback) => pushApi('get', '/productgroups', {}, callback),

    // Brands
    createBrand: (payload, callback) => pushApi('post', '/brands', payload, callback),
    getBrand: (payload, callback) => pushApi('get', `/brand/${payload.id}?sessionUser=${payload.sessionUser}`, payload, callback),
    getAgency: (payload, callback) => pushApi('get', `/agency/${payload.id}?sessionUser=${payload.sessionUser}`, payload, callback),

    // Favorites
    addToFavorites: (payload, callback) => pushApi('post', '/add-to-favorites', payload, callback),
    getFavorites: (payload, callback) => pushApi('get', `/favorites?owner=${payload.owner}&owner_id=${payload.owner_id}`, {}, callback),

    // File Upload
    uploadAttachment: (payload, callback) => pushUpload('post', '/attachments', payload, callback),
    getUploadUrl: () => { return getEnv('API_URI')+'storage' },

    // Contacts
    requestContact: (payload, callback) => pushApi('post', '/contact-requests', payload, callback),

    // Search
    search: (payload, callback) => pushApi('get', `/search?${payload.params}`, {}, callback),
    // search: (payload, callback) => { console.log(payload) },
}

const pushApi = (action, route, payload, callback) => {
    instance[action](`api${route}`, payload)
    .then((res) => {
        callback(res);
    });
}

const pushAuth = (action, route, payload, callback) => {
    instance[action](`oauth${route}`, payload)
    .then((res) => {
        callback(res);
    });
}

const pushUpload = (action, route, payload, callback) => {
    instance[action](`api${route}`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
    .then((res) => {
        callback(res);
    });
}

export default Endpoints;