import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useLocation, useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import api from '../../api';
import { connect } from "react-redux";
import { logout, updateUser } from "../../redux/reducers/userReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const PageCreateBrand = ({user}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const nextHref = `/`;

  const initialValues = {
    brandowner_id: user?.company?.id ?? 0,
    name: '',
    description: '',
    categories: [],
  }
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [isOptionsLoaded, setOptionsLoaded] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    if(!isOptionsLoaded) {
      api.getProductGroups((res) => {
        setCategoryOptions(res.data);
        setOptionsLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    setInputValues((prevState) => ({
      ...prevState,
      brandowner_id: user?.company?.id ?? 0,
    }));
  }, [user]);

  const handleOnCategoryChange = (e) => {
    const {
      target: { value },
    } = e;
    setInputValues((prevState) => ({
      ...prevState,
      categories: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleCreateBrand = () => {
    api.createBrand(inputValues, (res) => {
      console.log(res.data);
      // brand is created,
      // next page for assets
      navigate('/brand/images', { state: { brand: res.data } });
    });
  }

  return (
    <CommonLayout
      index="01"
      maxIndex={'2'}
      backtHref="/register"
      nextHref={nextHref}
      onSubmit={handleCreateBrand}
    >
      <>
        <h2 className="text-2xl font-semibold">Brand Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem
            label="Brand Name"
          >
            <Input 
              placeholder="Brand name"
              name='name'
              onChange={handleInputChange}
               />
          </FormItem>
          {/* ITEM */}
          <FormItem
            label="Product Groups"
          >
            <Select
              className="fullWidth"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={inputValues?.categories}
              onChange={handleOnCategoryChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((i) => (
                    <Chip key={i.name} label={i.name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {categoryOptions.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item}
                  style={getStyles(item.name, inputValues?.categories, theme)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormItem>
          <>
            <div>
              <h2 className="text-2xl font-semibold">
                About the Brand
              </h2>
            </div>

            <Textarea 
              placeholder="..."  
              rows={14}
              name='description'
              onChange={handleInputChange} />
          </>
        </div>
      </>
    </CommonLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { updateUser, logout })(
  PageCreateBrand
);
