import axios from "axios";
import {getEnv} from './environments';

class Connection {
    static getBaseUrl = () => {
        return getEnv('API_URI');
    }
}

const token = JSON.parse( localStorage.getItem('token') );
let access_token = null;
if(token) {
  access_token = token.access_token;
}
let instance = axios.create({
  baseURL: Connection.getBaseUrl()
});

instance.defaults.timeout = 150000;

instance.interceptors.request.use(
  (config) => {
    if (window.navigator.onLine) {
      if (access_token != null) {
        config.headers.Authorization = `Bearer ${access_token}`;
        config.headers.Accept=`application/json`;
      }
      return config;
    } else {
      return Promise.reject(new Error("Check Your Internet Connection"));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(response => response, error => {
  console.log(error);
  if(error.response) {
    if (error.response.status === 400) {
    //   Swal.fire(error.response.data.message, "", "error");
      console.error(error.response.data.message);
    } else if (error.response.status === 401) {
      localStorage.clear();
      // Swal.fire("Unauthorized accesssssss!", "", "error");
      console.error(error.response.data.message);
      
      // window.location.href = "/";
    } else if (error.response.status === 500) {
      console.error(error.response.data.message);
    //   Swal.fire("Internal server error!", "", "error");
    }
  }
  return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    if(response?.data?.access_token !== undefined) {
      access_token = response?.data?.access_token;
      localStorage.setItem('token', JSON.stringify(response?.data));
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });


export default instance;
