import { createSlice } from '@reduxjs/toolkit';

const brandSlice = createSlice({
  name: 'brand',
  initialState: { brand: {
    name: '',
    brandowner_id: 0,
    brandowner: null,
    description: '',
    images: [],
    isFavorite: false,
  } },
  reducers: {
    setBrand: (state, action) => {
        const updates = action.payload; // Destructure updates from the action payload
        state.brand = { ...state.brand, ...updates }; // Merge the updates into the state
    },
  },
});

export const { setBrand } = brandSlice.actions;

export default brandSlice.reducer;