import React, { FC, Fragment, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "./SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "./Layout";
import GuestsInput from "./GuestsInput";
import { connect, useDispatch } from "react-redux";
import { updateLoadingIsStatus } from "../../redux/reducers/spinnerReducer";
import api from '../../api';
import { setBrand } from "../../redux/reducers/brandReducer";

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert } from "@mui/material";
import ButtonThird from "shared/Button/ButtonThird";

const BrandDetailContainer = ({brand, user}) => {

    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
    const [sbState, setSBState] = useState({
      open: false,
    });
    const { open } = sbState;
    const [pageLabel, setPageLabel] = useState('');

    const [isContactRequested, setisContactRequested] = useState(false);

    const thisPathname = useLocation().pathname;
    const router = useNavigate();
  
    const handleOpenModalImageGallery = () => {
      router(`${thisPathname}?modal=PHOTO_TOUR_SCROLLABLE`);
    };

    const handleContactRequest = () => {
      api.requestContact({
        requester_type: user?.company_type,
        requester_id: user?.company?.id,
        contact_type: brand?.company_type,
        contact_id: brand?.brandowner?.id,
      }, (res) => {
        openSuccessSnackbar({ vertical: 'bottom', horizontal: 'center' });
        setisContactRequested(true);
      });
    }

    const openSuccessSnackbar = (position) => {
      setSBState({ ...position, open: true });
    }

    const handleCloseSB = () => {
      setSBState({ ...sbState, open: false });
    }

    useEffect(() => {
      setPageLabel(brand?.company_type == 'agencies' ? 'Agency' : 'Brand');
    }, [brand]);
  
    const renderSection1 = () => {
      return (
        <div className="listingSection__wrap !space-y-6">
          {/* 1 */}
          <div className="flex justify-between items-center">
            <Badge name={pageLabel} />
            <LikeSaveBtns />
          </div>
  
          {/* 2 */}
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {brand?.name}
          </h2>
  
          {/* 3 */}
          <div className="flex items-center space-x-4">
            <StartRating />
            <span>·</span>
            <span>
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1"> {`${brand?.brandowner?.city}, ${brand?.brandowner?.country}`}</span>
            </span>
          </div>
  
          {/* 4 */}
          <div className="flex items-center">
            <Avatar imgUrl={brand?.brandowner?.avatar ? api.getUploadUrl()+'/'+brand?.brandowner?.avatar?.url : null } hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
            <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                by{" "}
              <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                {brand?.brandowner?.name}
              </span>
            </span>
          </div>

        </div>
      );
    };
  
    const renderSection2 = () => {
      return (
        <div className="listingSection__wrap">
          <h2 className="text-2xl font-semibold">{pageLabel} information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="text-neutral-6000 dark:text-neutral-300">
            {brand?.description}
          </div>
        </div>
      );
    };

    const renderSidebar = () => {
      return (
        <div className="listingSectionSidebar__wrap shadow-xl">
          {/* PRICE */}
          <div className="flex justify-between">
            <span className="text-3xl font-semibold">
              {brand?.brandowner?.name}
            </span>
          </div>
  
  
          {/* SUM */}
          <div className="flex flex-col space-y-4">

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>
  
          {/* SUBMIT */}
          {
            !isContactRequested ? (
              <ButtonPrimary href={"#"} onClick={handleContactRequest}>Request Contact</ButtonPrimary>
            ) : (
              <ButtonThird>Contact Requested</ButtonThird>
            )
          }
        </div>
      );
    };

    return (
      <div className="nc-ListingStayDetailPage">
        {/*  HEADER */}
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={brand?.imageUrls[0] ?? ''}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {brand?.imageUrls.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item || ""}
                    alt=""
                    sizes="400px"
                  />
                </div>
  
                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}
  
            <button
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="w-5 h-5" />
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </button>
          </div>
        </header>
  
        {/* MAIN */}
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderSection1()}
            {renderSection2()}
          </div>
  
          {/* SIDEBAR */}
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div>
        </main>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleCloseSB}
          message="Contact Requested"
        >
          <Alert
            onClose={handleCloseSB}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Contact Request Sent!
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
const mapStateToProps = (state) => ({
    user: state.user.user,
    isLoading: state.spinner.isLoading,
    brand: state.brand.brand,
  });
  
export default connect(mapStateToProps, { updateLoadingIsStatus, setBrand })(
    BrandDetailContainer
);