import React, { ReactNode, useEffect } from "react";
import { imageGallery as listingStayImageGallery } from "./constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { connect } from "react-redux";

const DetailPagetLayout = ({ children, brand}) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if(brand?.imageUrls === undefined) return [];
    let imgs = brand?.imageUrls.map((url, id) => {
      return {
        id,
        url,
      }
    })

    return imgs;
  };

  useEffect(() => {
    console.log(brand, children);
  }, []);

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div> */}
      </div>

      {/* STICKY FOOTER MOBILE */}
      <MobileFooterSticky />
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand.brand,
});

export default connect(mapStateToProps, {  })(
  DetailPagetLayout
);
