import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import spinnerReducer from './reducers/spinnerReducer';
import brandReducer from './reducers/brandReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    spinner: spinnerReducer,
    brand: brandReducer,
  },
});

export default store;