import { createSlice } from '@reduxjs/toolkit';

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState: { isLoading: false },
  reducers: {
    updateLoadingIsStatus: (state, action) => {
      const bool = action.payload; // Destructure updates from the action payload
      console.log('changing loading status ' , bool);
        state.isLoading = bool;
    },
  },
});

export const { updateLoadingIsStatus } = spinnerSlice.actions;

export default spinnerSlice.reducer;