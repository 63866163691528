const environment = {
	production: {
		API_URI: "https://sv360.limokon.work/be/public/",
	},

	staging: {
		API_URI: "https://sv360.limokon.work/be/public/",
	},

	development: {
		API_URI: "http://localhost:8000/",
	}
}

export const getEnv = function (key) {
	let env = process.env.REACT_APP_ENVIRONMENT
	return environment[env][key]
}
