import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonToggle from "shared/Button/ButtonToggle";
import { Link, useNavigate } from "react-router-dom";
import ButtonThird from "shared/Button/ButtonThird";
import { Grid } from "@mui/material";
import api from '../../api';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [companyType, setCompanyType] = useState('brand');
  
  const initialValues = {
    name: '',
    email: '',
    password: '',
  }
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRegisterSubmit = () => {
    api.registerUser(inputValues, (res) => {
      navigate(`/register/${companyType}`, { state: { user: res.data }});
    });
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Agent Tinder | Register</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Register
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              WE ARE A
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <div className="grid gap-3">
            <Grid container direction={'row'} sx={{ flex: 1 }}>
              <ButtonToggle onClick={()=> setCompanyType('brand')} active={companyType=='brand'}>Brand</ButtonToggle>
              <ButtonToggle onClick={()=> setCompanyType('agency')} active={companyType=='agency'}>Agency</ButtonToggle>
            </Grid>
          </div>
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Your Name
              </span>
              <Input
                type="text"
                name='name'
                placeholder="John Doe"
                className="mt-1"
                onChange={(e) => handleInputChange(e)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name='email'
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e) => handleInputChange(e)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input 
                type="password" 
                name='password' 
                onChange={(e) => handleInputChange(e)}
                className="mt-1" />
            </label>
            <ButtonPrimary onClick={handleRegisterSubmit}>Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
