import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import BrandCard from "components/BrandCard/StayCard";
import { updateLoadingIsStatus } from "../../redux/reducers/spinnerReducer";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import api from '../../api';
import { connect, useDispatch } from "react-redux";

const PageFavorites = ({user}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [faveList, setFaveList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if(user?.id==0)return;
    if(!isLoaded) {
      dispatch(updateLoadingIsStatus(true));
      api.getFavorites({
        owner: user?.company_type,
        owner_id: user?.company?.id,
      }, (res) => {
        console.log(res.data);
        setFaveList(res.data);
        dispatch(updateLoadingIsStatus(false));
      });
    }
  }, [user]);

  return (
    <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">My Favorites</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {
              faveList.map((f) => {
                let data = {
                  galleryImgs: f.images.map((b)=>`${api.getUploadUrl()}/${b.url}`),
                  title: f.name,
                  href: `/${f.company_type=='agencies' ? 'agency' : 'brand'}/${f.id}`,
                  like: false,
                  saleOff: false,
                  isAds: false,
                  id: `b-${f.id}`,
                  extras: f,
                };
                return (
                  <BrandCard key={f.id} data={data} />
                )
              })
            }
          </div>
        </div>
      </div>
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { updateLoadingIsStatus })(
  PageFavorites
);
