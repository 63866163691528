import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from '../../api';
import { LinearProgress, Stack } from "@mui/material";

const PageAttachImages = ({user, attachmentKey, multiple, imgSet}) => {
  let initialImages = imgSet.map((i) => {return {
    isLoading: false,
    name: i.url,
    url: api.getUploadUrl()+'/'+i.url,
  }} );
  const [images, setImages] = useState(initialImages);
  const [imagesUploading, setImagesUploading] = useState([]);

  const isFileExists = (f) => {
    for(let i in images) {
      if(images[i].name == f.name) return true;
    }
    return false;
  }

  const handleOnChange = (e) => {
    let imagesTmp = [].concat(images);

    let files = e.target.files;
    for(let i in files) {
      let file = files[i];
      if(file.name === undefined) return;
      if(isFileExists(file)) return;
      imagesTmp.push({
        isLoading: true,
        name: file.name,
        url: '',
      });
      setImagesUploading(imagesTmp);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("key", attachmentKey);
      formData.append("entity", user?.company_type == 'agencies' ? 'agencies' : 'brandowners');
      formData.append("entity_id", user?.company?.id);

      api.uploadAttachment(formData, (res) => {
        cleanUpLoadingImages(file.name);
        handleUploadSuccess(res.data);
      });
    }
  }

  const cleanUpLoadingImages = (name) => {
    let tmp = [].concat(images);
    for(let i=0 ; i<tmp.length ; i++) {
      if(name == tmp[i].name) {
        tmp.splice(i, 1); // 2nd parameter means remove one item only
      }
    }
    setImagesUploading(tmp);
  }

  const handleUploadSuccess = (file) => {
    console.log(file);
    file.url = `${api.getUploadUrl()}/${file.url}`;
    let tmp = [].concat(images);
    tmp.push(file);
    setImages(tmp);
  }

  useEffect(() => {
  }, []);

  return (
      <>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <div>

            <div className="mt-5">
              <Stack 
                direction={'row'} 
                spacing={1}
                useFlexGap
                sx={{ flexWrap: 'wrap' }}
                >
                {
                  images && images.map((r, i) => {
                    return (<ImageItem {...r} />)
                  })
                }
                {
                  imagesUploading && imagesUploading.map((r, i) => {
                    if(r.isLoading)
                      return (<ImageItem {...r} />)
                    return null;
                  })
                }
              </Stack>
            </div>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor={`file-upload-${attachmentKey}`}
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload files</span>
                      <input
                        id={`file-upload-${attachmentKey}`}
                        name="files"
                        type="file"
                        multiple={multiple}
                        className="sr-only"
                        onChange={handleOnChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default PageAttachImages;

const ImageItem = ({isLoading, name, url}) => {
  if(url=='')
    return (
      <div className="image-item-box space-y-1 text-center">
        <div>{name}</div>
        {
          isLoading ? <LinearProgress sx={{ bottom: '0' }} /> : null
        }
      </div>
    );
  else
    return (
      <div className="image-item-box space-y-1 text-center" style={{ background: `url("${url}")`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
      </div>
  );
}