import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonToggle from "shared/Button/ButtonToggle";
import { Link, useNavigate } from "react-router-dom";
import ButtonThird from "shared/Button/ButtonThird";
import { Grid, Button, Chip } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import api from '../../api';
import { useError } from "react-use";
import { table } from "console";
{/* <Chip label={params.value.type} color='primary' /> */}

const paginationModel = { page: 0, pageSize: 5 };

export interface AdminPageApplicationsProps {
  className?: string;
}

const AdminPageApplications: FC<AdminPageApplicationsProps> = ({ className = "" }) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const fetchPendingApplications = () => {
    api.getPendingApplications((res) => {
      setTableData(res.data);
      console.log(res.data);
    });
  }

  const getRowId = (r) => {
    return r.rid;
  }

  useEffect(() => {
    fetchPendingApplications();
  }, []);

  const approveApplication = (r) => {
    api.updateCompany({
      id: r.cid,
      type: r.type,
      is_confirmed: 1,
    }, () => {
      updateTableData(r);
    });
  }

  const denyApplication = (r) => {
    api.deleteCompany({
      id: r.cid,
      type: r.type,
    }, () => {
      updateTableData(r);
    });
  }

  const updateTableData = (r) => {
    const index = tableData.indexOf(r);
    let td = JSON.parse(JSON.stringify(tableData));
    if (index > -1) { 
      td.splice(index, 1);
    }
    setTableData(td);
  }

  const columns: GridColDef[] = [
    { field: 'type', 
      headerName: 'Type', 
      renderCell: (params: GridRenderCellParams<any, any>) => (
        params?.row?.type=='brandowners' ? (<Chip label="Brand" color='primary' />) : (<Chip label="Agency" />)
      )
    },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'owner_name', headerName: 'Owner' },
    { field: 'actions', 
      headerName: 'Actions',
      sortable: false,
      width: 250,
      renderCell: (params: GridRenderCellParams<any, any>) => 
        (
          <Grid container direction={'row'} sx={{ flex: 1 }}>
            <Grid item className="fullWidth">
              <Button color='success' variant='contained' onClick={() => approveApplication(params?.row)}>Approve</Button>
            </Grid>
            <Grid item className="fullWidth">
              <Button color='warning' variant="outlined" onClick={() => denyApplication(params?.row)}>Deny</Button>
            </Grid>
          </Grid>
        )
    },
  ];

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Agent Tinder | Register</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Pending Applications
        </h2>
        <div className="mx-auto space-y-6 ">
          <div className="relative text-center">
            
            <Paper sx={{ height: '100%', width: '100%' }}>
              <DataGrid
                rows={tableData}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row: any) =>  getRowId(row)}
                sx={{ border: 0 }}
              />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPageApplications;
