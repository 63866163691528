import { MapPinIcon } from "@heroicons/react/24/solid";
import Label from "components/Label/Label";
import { FC, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { COUNTRIES } from "data/countries";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../api";

export interface PageAddListing2Props {}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const {state} = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const companyType = location.pathname.indexOf('brand')>-1 ? 'brand' : 'agency';

  let {input} = state;
  const initialValues = {
    ...input
  };
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = () => {
    // push submit
    api.registerCompany(inputValues, () => {
      navigate('/register/thank-you');
    });
  }

  return (
    <CommonLayout
      index="02"
      nextHref={`/thank-you`}
      backtHref={`/register/${companyType}`}
      onSubmit={handleOnSubmit}
    >
      <>
        <h2 className="text-2xl font-semibold">Office Address</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Country/Region">
            <Select
              name='country'
              value={inputValues?.country}
              onChange={handleInputChange}
              >
              <option value="DE">Germany</option>
              {
                COUNTRIES.map((c) => (
                  <option value={c.code}>{c.name}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input 
              placeholder="..."
              name='street_address'
              onChange={handleInputChange}
               />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="City">
              <Input
                name='city'
                onChange={handleInputChange}
               />
            </FormItem>
            <FormItem label="Post">
              <Input 
                name='post'
                onChange={handleInputChange}
              />
            </FormItem>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
