import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link, useLocation } from "react-router-dom";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { connect } from "react-redux";

export interface MainNav2Props {
  className?: string;
  user?: any;
}

const MainNav2: FC<MainNav2Props> = ({ className = "", user }) => {
  const pathname = useLocation().pathname;
  const [showSearch, setShowSearch] = useState(false);
  const [userLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if(userLoaded) return;
    if(user?.company) {
      setShowSearch(true)
      setLoaded(true);
    }
  }, [user]);

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start s:flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
        </div>

        {
          showSearch ? (
            <div className="flex-[3] max-w-lg !mx-auto md:px-3 text-center items-center">
              <HeroSearchForm2MobileFactory />
            </div>
          ) : null
        }

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            {/* <LangDropdown /> */}

            <div></div>
            <SwitchDarkMode />
            <div className="pr-1.5">
              {/* <NotifyDropdown className="-ml-2 xl:-ml-1" /> */}
            </div>
            <AvatarDropdown />
          </div>
          <div className="flex items-center space-x-2 lg:hidden">
            {/* <NotifyDropdown /> */}
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  isLoading: state.spinner.isLoading,
});

export default connect(mapStateToProps, {  })(
  MainNav2
);