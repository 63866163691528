import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useLocation, useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";

export interface PageCompanyRegisterProps {}

const PageCompanyRegister: FC<PageCompanyRegisterProps> = () => {
  const location = useLocation();
  const {state} = useLocation();
  const navigate = useNavigate();
  const companyType = location.pathname.indexOf('brand')>-1 ? 'brand' : 'agency';
  const nextHref = `/register/${companyType}/2`;

  const initialValues = {
    user_id: state?.user?.id,
    company_type: companyType,
    name: '',
    description: '',
    business_type: "GmbH",
    street_address: "",
    post: "",
    city: "",
    country: "DE",
    email: "",
    website: "",
    phone: "",
    lat: 0,
    lng: 0,
  }
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCompanySubmit = () => {
    console.log(inputValues);
    navigate(nextHref, { state: { input: {...inputValues} } });
  }

  return (
    <CommonLayout
      index="01"
      backtHref="/register"
      nextHref={nextHref}
      onSubmit={handleCompanySubmit}
    >
      <>
        <h2 className="text-2xl font-semibold">Company Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem
            label="Company name"
          >
            <Input 
              placeholder="Company name"
              name='name'
              onChange={handleInputChange}
               />
          </FormItem>
          {/* ITEM */}
          <FormItem
            label="Business Type"
          >
            <Select 
              name='business_type'
              value={inputValues?.business_type}
              onChange={handleInputChange}
              >
              <option value="GmbH">GmbH</option>
              <option value="KG">KG</option>
              <option value="OHG">OHG</option>
              <option value="GBR">GBR</option>
              <option value="UG ">UG</option>
              <option value="AG">AG</option>
            </Select>
          </FormItem>
          <FormItem
            label="Email"
          >
            <Input 
              placeholder="Email"
              name='email'
              onChange={handleInputChange}
             />
          </FormItem>
          <FormItem
            label="Website"
          >
            <Input 
              placeholder="Website"
              name='website'
              onChange={handleInputChange} />
          </FormItem>
          <FormItem
            label="Phone"
          >
            <Input 
              placeholder="Telefon"
              name='phone'
              onChange={handleInputChange} />
          </FormItem>
          <>
            <div>
              <h2 className="text-2xl font-semibold">
                About the Company
              </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Mention the best features of your product or service or the history of your company.
              </span>
            </div>

            <Textarea 
              placeholder="..."  
              rows={14}
              name='description'
              onChange={handleInputChange} />
          </>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageCompanyRegister;
