import React, { FC, Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailPagetLayout from "./Layout";
import { connect, useDispatch } from "react-redux";
import { updateLoadingIsStatus } from "../../redux/reducers/spinnerReducer";
import api from '../../api';
import { setBrand } from "../../redux/reducers/brandReducer";
import BrandDetailContainer from "./BrandDetailContainer";

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const PageBrandDetailContainer = ({isLoading, user}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isBrandLoaded, setIsBrandLoaded] = useState(false);
  const [brandDetail, setBrandDetail] = useState(null);

  const pathname = useLocation().pathname;

  const prepareBrandDetail = (data) => {
    let d = data;
    d.imageUrls = d.images.map((b) => `${api.getUploadUrl()}/${b.url}`);

    dispatch(setBrand(d));
    setIsBrandLoaded(true);
  }

  useEffect(() => {
    if(!isBrandLoaded) {
      if(user?.company == null) return;
      dispatch(updateLoadingIsStatus(!isBrandLoaded));
      // load brand data
      let target = capitalizeFirstLetter(pathname.split('/')[1]);
      let endpoint = `get${target}`;
      api[endpoint]({id, sessionUser: user?.company?.id}, (res) => {
        prepareBrandDetail(res.data);
      });
    }
  });

  useEffect(() => {
      dispatch(updateLoadingIsStatus(!isBrandLoaded));
  }, [isBrandLoaded]);

  return (
    <DetailPagetLayout>
      {
        isBrandLoaded ? (
          <BrandDetailContainer />
        ) : null
      }
    </DetailPagetLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  isLoading: state.spinner.isLoading,
  brand: state.brand,
});

export default connect(mapStateToProps, { updateLoadingIsStatus, setBrand })(
  PageBrandDetailContainer
);