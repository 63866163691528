import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Header3 from "components/Header/Header3";
import Header from "components/Header/Header";
import {
  ShoppingBagIcon as ShoppingCartIcon,
  Cog8ToothIcon as CogIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";
import { connect } from "react-redux";
import { Alert, Button, LinearProgress } from "@mui/material";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export type SiteHeaders = "Header 1" | "Header 2" | "Header 3";

interface HomePageItem {
  name: string;
  slug: PathName;
}

let OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};
let OBSERVER: IntersectionObserver | null = null;
const PAGES_HIDE_HEADER_BORDER: PathName[] = [
  "/home-3",
  "/listing-car-detail",
  "/listing-experiences-detail",
  "/listing-stay-detail",
];

const SiteHeader = ({ user, isLoading }) => {
  const navigate = useNavigate();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [headerSelected, setHeaderSelected] =
    React.useState<SiteHeaders>("Header 2");

  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
  };

  useEffect(() => {
    setHeaderSelected("Header 2");

    // disconnect the observer
    if (!PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
      OBSERVER && OBSERVER.disconnect();
      OBSERVER = null;
      return;
    }
    if (!OBSERVER) {
      OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
      anchorRef.current && OBSERVER.observe(anchorRef.current);
    }
  }, [location.pathname]);

  const renderHeader = () => {
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";
    if (PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
      headerClassName = isTopOfPage
        ? ""
        : "shadow-sm dark:border-b dark:border-neutral-700";
    }
    return <Header className={headerClassName} navType="MainNav2" />;
  };

  const gotToCreateBrand = () => {
    navigate('/create-brand');
  }

  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Agent Tinder | Brand Search</title>
      </Helmet>
      {renderHeader()}
      {
        isLoading ? (<LinearProgress />) : null
      }
      
      {
        user?.company_type == 'brandowners' && user?.company?.brands?.length==0 ? (
          <div>
            <Alert severity="warning">
              You have not completed setting up your profile.
              <Button sx={{ marginLeft: 4 }} variant="outlined" onClick={gotToCreateBrand} size='small'>Create Brand Profile</Button>
            </Alert>
          </div>
        ) : null
      }
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  isLoading: state.spinner.isLoading,
});

export default connect(mapStateToProps, {  })(
  SiteHeader
);
