import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonToggle from "shared/Button/ButtonToggle";
import { Link, useNavigate } from "react-router-dom";
import ButtonThird from "shared/Button/ButtonThird";
import { Grid } from "@mui/material";
import api from '../../api';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [companyType, setCompanyType] = useState('brand');
  
  const initialValues = {
    name: '',
    email: '',
    password: '',
  }
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRegisterSubmit = () => {
    api.registerUser(inputValues, (res) => {
      navigate(`/register/${companyType}`, { state: { user: res.data }});
    });
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Agent Tinder | Register</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          dank je wel
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              Thank you for signing up! You will receive a confirmation after we review your application.
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
