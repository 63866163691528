import React from "react";
import api from '../api';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const LikeSaveBtns = ({user, brand}) => {

  const handleLike = () => {
    api.addToFavorites({
      owner: user.company_type,
      brand_id: user?.company_type == 'brandowners' ? user?.company?.id : brand?.id,
      agency_id: user?.company_type == 'brandowners' ? brand.id : user.company?.id,
      preference: 'favorite'
    }, () => {});
  }

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span>
        <Link to={'#'} onClick={handleLike} className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">{brand?.isFavorite ? 'Remove From Favorites' : 'Add to Favorites'}</span>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  brand: state.brand.brand,
});

export default connect(mapStateToProps, {  })(
  LikeSaveBtns
);