import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonToggleProps extends ButtonProps {
    active: any
}

const ButtonToggle: React.FC<ButtonToggleProps> = ({
    className = " ",
		active=false,
    ...args
}) => {
	switch(active) {
		case true: return <ButtonPrimary {...args}></ButtonPrimary>;
		default: return <ButtonSecondary {...args}></ButtonSecondary>;
	}
};

const ButtonSecondary: React.FC<any> = ({
  className = " ",
  ...args
}) => {
  return (
    <Button
      className={`fullWidth ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 ${className}`}
      {...args}
    />
  );
};

const ButtonPrimary: React.FC<any> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`fullWidth ttnc-ButtonPrimary theme-cyan-blueGrey disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-500 text-neutral-50 ${className}`}
      {...args}
    />
  );
};

export default ButtonToggle;
